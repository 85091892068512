<template>
  <div class="ml-3 text-center" v-if="data">
    <v-avatar
      v-if="false"
      size="48"
      :color="disabled ? 'grey white--text' : 'color3 color3Text--text'"
    >
      <v-avatar
        size="36"
        :color="disabled ? 'grey darken-3' : 'color3 darken-3'"
      >
        {{rating.rating}}
      </v-avatar>
    </v-avatar>
    <v-chip :color="disabled ? 'grey white--text' : 'color1 color1Text--text'">
      {{rating.rating | ratingDisplay}}
    </v-chip>
    <div class="mt-n3 text-center">
      <v-chip x-small>{{data.system}}</v-chip>
    </div>
  </div>
</template>

<script>
export default {
  props: ['systemId', 'player', 'disabled'],
  computed: {
    data () {
      return this.player && this.systemId && this.player.ratings.find(f => f.systemId === this.systemId)
    },
    rating () {
      return this.data.rating
    }
  }
}
</script>
